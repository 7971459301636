<template>

  <!-- dialog:numpad:keys START -->
  <div class="numpad-holder">
    <div class="numpad">
      <div
        class="button"
        v-on:click="updatePin('1')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >1</div>
      <div
        class="button"
        v-on:click="updatePin('2')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >2</div>
      <div
        class="button"
        v-on:click="updatePin('3')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >3</div>
      <div
        class="button"
        v-on:click="updatePin('4')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >4</div>
      <div
        class="button"
        v-on:click="updatePin('5')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >5</div>
      <div
        class="button"
        v-on:click="updatePin('6')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >6</div>
      <div
        class="button"
        v-on:click="updatePin('7')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >7</div>
      <div
        class="button"
        v-on:click="updatePin('8')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >8</div>
      <div
        class="button"
        v-on:click="updatePin('9')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >9</div>
      <div
        :class="[
          'button remove-blue',
          { 'disabled' : pin.length <= 0},
        ]"
        v-on:click="deleteOne()"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >DEL</div>
      <div
        class="button"
        v-on:click="updatePin('0')"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >0</div>
      <div
        :class="[
          'button remove-all-blue disabled',
          { 'disabled' : pin.length <= 0},
        ]"
        v-on:click="deleteAll()"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
      >C</div>
    </div>
  </div>
  <!-- dialog:numpad:keys END -->

</template>

<script>
export default {
  name: 'numpad',
  props: {
    updatePin: {
      type: Function,
    },
    deleteOne: {
      type: Function,
    },
    deleteAll: {
      type: Function,
    },
    touchStart: {
      type: Function,
    },
    touchEnd: {
      type: Function,
    },
    pin: {
      type: String,
    },
  },
};
</script>
